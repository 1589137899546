import React, { Component } from 'react';
import axios from 'axios';

const AuthContext = React.createContext();

export class AuthProvider extends Component {
    state = {
      user_id: undefined,
      email: undefined,
      features: undefined,
      newsletter: undefined,
      plan: undefined,
      // plan_name: undefined,
      has_token: undefined,
      has_been_verified: undefined,
      translations: undefined
    }

    getAuthInfo = () => {
        //const controller = new AbortController();
        console.log("STATE before Authentication: ", this.state);
        axios.get('/api/check-cookies') // , {signal: controller.signal}
        .then(res => {
          console.log("Auth data sent back from server inside res.data: ", res.data);
          //console.log("YO: ", res.data.verified);
          // store user in the Context to make it available for all the children
          if (res.data.has_token !== 'undefined') {
            this.setState( {
                user_id: res.data.user_id,
                email: res.data.email,
                features: res.data.features,
                newsletter: res.data.newsletter,
                notifications: res.data.notifications,
                plan: res.data.plan,
                has_token: res.data.has_token,
                has_been_verified: res.data.has_been_verified,
                translations: res.data.translations
            }, () => {
              console.log("Double check the state after adding values from the res.data: ", this.state)
            })
          } else {
            this.setState({
              has_token: false
            })
          }
          console.log("AuthContext.js: User global context has been set to: ", this.state);
          
        })
        .catch (err => {
          console.log(err);
        });
        //return controller;
      }

  render() {
    const { user_id, plan, email, features, newsletter, notifications, has_token, has_been_verified, translations } = this.state;
    const {getAuthInfo} = this; // const {logOut} = this;  // this for later for having the logout function available in context

    return (
        <AuthContext.Provider value={{
          user_id,
          email,
          features,
          newsletter,
          notifications,
          plan,
          has_token,
          has_been_verified,
          translations,
          getAuthInfo
        }}>
          {this.props.children}
        </AuthContext.Provider>
    )
    
  }
}

export default AuthContext;