import React from 'react';
import { Container, Row, Col, Button, Card, Image, CardGroup, Badge } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { LinkContainer } from 'react-router-bootstrap';

import linkchimpImage from '../mockup-statistics.png';
import meelisImage from '../meelis.jpg';
import socialImage from '../gutbound-social-sharing.png';
import Logo from '../gutbound-logo-big.png';


function Home() {
    return (
        <>
            <Helmet>
                <title>GutBound - manage your IBS better</title>
                <meta name="description" key="description" content="GutBound is a mobile app for treating irritable bowel syndrome" />
                <meta
                    property="og:image"
                    key="og:image"
                    content={socialImage}
                />  
                <meta
                    property="og:title"
                    key="og:title"
                    content="GutBound - manage your IBS better"
                />
                <meta
                    property="og:description"
                    key="og:description"
                    content="GutBound is a mobile app for treating irritable bowel syndrome"
                /> 
            </Helmet>

            <Container style={{"padding": "3% 3%"}}>
                <Row style={{"margin": "0% 3%", "marginBottom": "5%", "alignItems": 'center'}}>
                <Col xs={{ span: 12, order: 1 }}  lg={{ span:1, order: 1 }} style={{"paddingBottom": "5%"}}>
                    </Col>
                    <Col xs={{ span: 12, order: 2 }}  lg={{ span: 5, order: 2 }}>
                        <h4 className="display-5 mb-5">Get your life back from IBS</h4>
                        <h4>GutBound is novel mobile app to treat your IBS with the help of AI.</h4>
                        <br />
                        <a href="https://docs.google.com/forms/d/e/1FAIpQLSdn99y4lqsrUBPaQ3l6Xnqv608vW9PGlro1DTsIlgfJC4DUgA/viewform?usp=sf_link" target="_blank" rel="noreferrer"><Button size="lg" className="me-3" variant="success">Sign up to waitlist</Button></a>
                        {/* <br /><br />
                        <p>No credit card required.</p> */}
                    </Col>
                    <Col xs={{ span: 12, order: 1 }}  lg={{ span:5, order: 3 }} style={{"paddingBottom": "5%", "justifyContent": "center", "display": "flex"}}>
                        <img
                            src={linkchimpImage}
                            alt="Logo"
                            className='img-fluid'
                            style={{ maxHeight: '500px' }}
                        />
                    </Col>
                    <Col xs={{ span: 12, order: 1 }}  lg={{ span:1, order: 4 }} style={{"paddingBottom": "5%"}}>
                    </Col>
                </Row>
                <br /><br />

                <hr />

                <h1 className='text-center' style={{"marginBottom": "7%", "marginTop": "10%"}}>Let's Get A Few Things Straight...</h1>

                <CardGroup style={{"marginBottom": "0%"}}>
                    <Card className="border-0 bg-transparent">
                        <Card.Body>
                        <Card.Title>IBS isn't caused by nutrition</Card.Title>
                        <Card.Text>
                        If it was, everyone with similar nutrition would get it.
                        </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="border-0 bg-transparent">
                        <Card.Body>
                        <Card.Title>IBS can't be cured through nutrition</Card.Title>
                        <Card.Text>
                        If it could, changing diet would cure everyone.
                        </Card.Text>
                        </Card.Body>
                    </Card>
                </CardGroup>

                <CardGroup style={{"marginBottom": "13%"}}>
                    <Card className="border-0 bg-transparent">
                        <Card.Body>
                        <Card.Title>You body is trying to tell you something</Card.Title>
                        <Card.Text>
                        It's trying to tell you something so bad, it attacks itself to notify you.
                        </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="border-0 bg-transparent">
                        <Card.Body>
                        <Card.Title>Trust your gut feeling</Card.Title>
                        <Card.Text>
                        And start digging deeper into what's behind it.
                        </Card.Text>
                        </Card.Body>
                    </Card>
                </CardGroup>

                <hr />

                <h1 className='text-center' style={{"marginBottom": "7%", "marginTop": "10%"}}>How GutBound Works</h1>

                <CardGroup style={{"marginBottom": "0%"}}>
                    <Card className="border-0 bg-transparent">
                        <Card.Body>
                        <Card.Title>Log your symptom occurrences</Card.Title>
                        <Card.Text>
                        It's important to know when your symptoms occur. This way you can start seeing patterns.
                        </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="border-0 bg-transparent">
                        <Card.Body>
                        <Card.Title>Consult with AI</Card.Title>
                        <Card.Text>
                        Sometimes it's hard to know what was the actual trigger. Your personal AI assistant can help you dig deeper and find out. And offer advice.
                        </Card.Text>
                        </Card.Body>
                    </Card>
                </CardGroup>

                <CardGroup style={{"marginBottom": "10%"}}>
                <Card className="border-0 bg-transparent">
                        <Card.Body>
                        <Card.Title>Make changes to your life</Card.Title>
                        <Card.Text>
                        IBS didn't just magically appear. And it won't magically disappear. You need to do the work. There are not shortcuts. But there is a solution.
                        </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="border-0 bg-transparent">
                        <Card.Body>
                        <Card.Title>
                            Monitor your progress
                            </Card.Title>
                        <Card.Text>
                        See the progress you've made. See how your symptoms have changed over time.
                        </Card.Text>
                        </Card.Body>
                    </Card>
                </CardGroup>

                <hr />
                <h1 className='text-center' style={{"marginBottom": "7%", "marginTop": "10%"}}>Designed For Privacy</h1>

                <CardGroup style={{"marginBottom": "10%"}}>
                    <Card className="border-0 bg-transparent">
                        <Card.Body>
                        <Card.Title>Your health data is kept on your device</Card.Title>
                        <Card.Text>
                        The data you enter about symptoms is not sent to our servers. It's stored only on your device.
                        </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="border-0 bg-transparent">
                        <Card.Body>
                        <Card.Title>Conversations with AI are private</Card.Title>
                        <Card.Text>
                        None of your conversations with AI are kept on our servers. They are stored locally on your device and only temporarily.
                        </Card.Text>
                        </Card.Body>
                    </Card>
                </CardGroup>


                <hr />
                <Row style={{"padding": "10% 5% 7% 5%"}}>
                    <Col xs={{ span: 12, order: 2}}  lg={{ span: 3, order: 1}} style={{"marginBottom": "5%"}}>
                        <Image
                            src={meelisImage}
                            alt="Meelis Ojasild"
                            roundedCircle={true}
                            style={{"maxWidth": "150px"}}
                        />
                    </Col>
                    <Col xs={{ span: 12, order: 1}}  lg={{ span: 9, order: 2}}>
                        <Card className="border-0">
                        <blockquote className="blockquote">
                            <em>“I got diagnosed with IBS a few years ago. I tried the FODMAP diet and it didn't work. I tried pills and it didn't work. So, I started reading up on the research and found a better way to treat the symptoms. I experimented until I found a method that worked. This has now turned into an app that anyone can use.”</em>
                        </blockquote>
                        <p>
                            - Meelis Ojasild, Founder of GutBound
                        </p>
                        </Card>
                    </Col>
                </Row>

                <hr />

                <div className='text-center' style={{"margin": "0% 3%"}}>
                        <img
                        src={Logo}
                        style={{"maxHeight": "100px", "marginTop": "15%"}}
                        className="d-inline-block align-top"
                        alt="GutBound logo"
                    />
                    <h1 className='text-center' style={{"paddingTop": "2%"}}>Get early access to GutBound</h1>
                    <h4 className='text-center' style={{"padding": "2% 0%", "fontWeight": "normal"}}>Relieve your IBS symptoms before it gets too bad.</h4>
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSdn99y4lqsrUBPaQ3l6Xnqv608vW9PGlro1DTsIlgfJC4DUgA/viewform?usp=sf_link" target="_blank" rel="noreferrer"><Button size="lg" className="me-3" variant="success">Sign up to waitlist</Button></a>
                </div>


    <br /><br /><br /><br />



            </Container>
        </>
    )
}

export default Home;