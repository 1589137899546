import React from 'react';
import { Container, Button, Card, CardGroup, Badge } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { LinkContainer } from 'react-router-bootstrap';

function Home() {
    return (
        <>
            <Helmet>
                <title>GutBound - manage your IBS better</title>
                <meta name="description" key="description" content="GutBound is a mobile app for treating irritable bowel syndrome" />
                <meta
                    property="og:title"
                    key="og:title"
                    content="GutBound - manage your IBS better"
                />
                <meta
                    property="og:description"
                    key="og:description"
                    content="GutBound is a mobile app for treating irritable bowel syndrome"
                /> 
            </Helmet>

            <Container style={{"padding": "3% 3%"}}>

                <h1 className='text-center' style={{"marginBottom": "7%"}}>Privacy Policy</h1>

                {/* <h2>Short Version</h2> */}

                <p>
                    <ul>
                        <li>We don't save any of your conversations with the AI to our servers.</li>
                        <li>We don't save any of your diary entries to our servers.</li>
                        <li>Your diary entries and conversations are kept locally on your device.</li>
                        <li>We may collect information that is essential for providing the service (your mobile device ID, for example).</li>
                        <li>We don't share any information we collect with third parties.</li>
                    </ul>
                </p>




    <br /><br /><br /><br />



            </Container>
        </>
    )
}

export default Home;