import React from 'react'
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';

export default function NotFound() {
  return (
    <>
      <Helmet>
        <title>Not Found - Lingo Champion</title>
        <meta name="description" content="Lingo Champion - Learn languages while you browse the web" />
      </Helmet>
      <Container className='d-flex align-items-center justify-content-center'>
      <div>Page not found. Try logging in if you think you saw this page as an accident.</div>
      </Container>
      
    </>
    
  )
}
