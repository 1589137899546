import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, ProgressBar } from "react-bootstrap";
import AuthContext from '../context/AuthContext';

export default class CampaignList extends React.Component {
    render() {
        console.log("CONTEXT IN CampaignList.js: ", this.context);
        return (
            <>

                <div style={{"maxWidth": "700px"}}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Statistics</Card.Title>
                            {/* switch plans based on context */}
                            {this.context.plan === 'Premium plan'
                                ?
                                    <>
                                    <Card.Text>
                                    This is your running monthly usage of translations in characters.
                                    </Card.Text>
                                    <Card.Text>
                                        <h6>Characters used: {this.context.translations.toLocaleString('en')} / 100,0000</h6>
                                    </Card.Text>
                                    <ProgressBar now={this.context.translations/100000*100} label={`${Math.round(this.context.translations/100000*100*10)/10}%`} className="my-3" />
                                    <Card.Text>
                                        Note that average word length varies a lot by language. In English it's about 5 characters per word. So if you translate 100 words, you'll use approximately 500 characters.
                                    </Card.Text>
                                    </>
                                :
                                    <>
                                    <Card.Text>
                                        Nothing to show here. Upgrade to a paid plan to see your usage statistics.
                                    </Card.Text>
                                    </>
                            }
                            
                        </Card.Body>
                    </Card>
                </div>
                
            </>
        )
    }
}

CampaignList.contextType = AuthContext;