import React from 'react'
import { LinkContainer } from 'react-router-bootstrap'

function Footer (props) {
  return (
    <>
        <div className='text-center p-2' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)', marginTop: "5%" }}>
            © {new Date().getFullYear()} GutBound
            {' '} | {' '}
            <LinkContainer to="/privacy-policy" style={{ "color": "black" }}><a href="/privacy" style={{ "color": "black"}}>Privacy policy</a></LinkContainer>
        </div>
  </>
  )
}

export default Footer